"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
window.addEventListener('load', function () {
  gsap.registerPlugin(ScrollTrigger);
  var horizontalScrollHandler = function horizontalScrollHandler() {
    var scroll = document.querySelector('.horizontal-scroll__inner');
    if (!scroll) {
      return;
    }
    document.addEventListener('scroll', function () {
      scroll.style.width = "".concat(document.documentElement.scrollTop * 100 / (document.documentElement.scrollHeight - document.documentElement.clientHeight), "%");
    });
  };
  var mobileMenuToggle = function mobileMenuToggle() {
    var navToggler = document.querySelector('.nav-toggler');
    var menuWrapper = document.querySelector('.mobile-menu__wrapper');
    var logoPaths = document.querySelectorAll('.js-logo-path');
    var mobileMenu = document.querySelector('.header-mobile-menu');
    if (!navToggler || !menuWrapper) {
      return;
    }
    navToggler.addEventListener('click', function () {
      navToggler.classList.toggle('menu-visible');
      menuWrapper.classList.toggle('visible');
      document.body.classList.toggle('scroll-lock');
      if (logoPaths.length) {
        logoPaths.forEach(function (path) {
          path.classList.toggle('menu-visible');
        });
      }
    });

    // // remove active state of navToggler when clicked on achor-link
    // if(mobileMenu){
    //   mobileMenu.addEventListener('click', (event) => {
    //     if(!event.target.classList.contains('header-mobile-menu')){
    //       navToggler.classList.remove('menu-visible');
    //     }
    //   });
    // }
  };
  var mobileMenuHandler = function mobileMenuHandler() {
    $('.header-mobile-menu .menu-item-has-children').click(function (event) {
      event.stopPropagation();
      $(this).children(".sub-menu").stop(true, true).slideToggle(); // Toggle only the clicked one
      $(this).toggleClass('sub-menu-is-visible');
    });
  };
  var refreshScrollTrigger = function refreshScrollTrigger() {
    var accordionLabels = document.querySelectorAll('.tariffs .accordion-checkbox');
    if (accordionLabels.length) {
      accordionLabels.forEach(function (label) {
        label.addEventListener('change', function () {
          ScrollTrigger.refresh();
        });
      });
    }
  };

  /* const tariffSlider = () => {
    const slider = document.querySelector('[data-tariff-slider]');
    const status = document.querySelector('[data-tariff-nav] .paging-info');
    const accordionLabels = document.querySelectorAll('[data-tariff-slider] .accordion-checkbox');
  
    if(!slider){
      return;
    }
      const sliderOffsetTop = offset(slider).top;
      const options = {
      dots: false,
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: true,
    };
  
    $(slider).on('afterChange', function(event, slick, currentSlide){
      window.scrollTo(0, sliderOffsetTop - 100);
    });
      $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      let i = (currentSlide ? currentSlide : 0) + 1;
      let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
      let html = `<span class="current-slide color-blue">${i >= 10 ? i : '0' + i}</span>
                  <span class="color-blue">/</span>
                  <span class="total-slides color-white">${totalSlides}</span>`;
      status.innerHTML = html;
    });
      if(accordionLabels.length){
      accordionLabels.forEach(label => {
        label.addEventListener('change', () => {
          ScrollTrigger.refresh();
          let slide = label.closest('.slick-slide');
          $(slider).find('.slick-list').height(slide.offsetHeight);
        });
      })
    }
  
    if(window.matchMedia('(max-width: 768px)').matches){
      $(slider).slick(options);
    }
  
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        if (slider.classList.contains('slick-initialized')){
          $(slider).slick('unslick');
        }
        return;
      }
      if (!slider.classList.contains('slick-initialized')){
        return $(slider).slick(options);
      }
    });
  
  
  
    $("[data-tariff-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
  
    $("[data-tariff-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
  
  } */

  var closePopup = function closePopup() {
    var closePopupBtns = document.querySelectorAll('[data-btn-close]');
    if (!closePopupBtns.length) {
      return;
    }
    closePopupBtns.forEach(function (btn) {
      btn.addEventListener('click', function () {
        btn.closest('.popup').classList.remove('visible');
        document.body.classList.remove('scroll-lock');
      });
    });
  };
  var proposalSlider = function proposalSlider() {
    var slider = document.querySelector('[data-proposal-slider]');
    var status = document.querySelector('[data-proposal-nav] .paging-info');
    if (!slider) {
      return;
    }
    var options = {
      dots: false,
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: true
    };
    if (status) {
      $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
        var html = "<span class=\"current-slide color-blue\">".concat(i >= 10 ? i : '0' + i, "</span>\n                    <span>/</span>\n                    <span class=\"total-slides color-white\">").concat(totalSlides, "</span>");
        status.innerHTML = html;
      });
    }
    if (window.matchMedia('(max-width: 768px)').matches) {
      $(slider).slick(options);
    }
    window.addEventListener('resize', function () {
      if (window.innerWidth > 768) {
        if (slider.classList.contains('slick-initialized')) {
          $(slider).slick('unslick');
        }
        return;
      }
      if (!slider.classList.contains('slick-initialized')) {
        return $(slider).slick(options);
      }
    });
    $("[data-proposal-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
    $("[data-proposal-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
  };
  var telephoneMask = function telephoneMask() {
    var telephoneInput = document.querySelector('input[type="tel"]');
    if (!telephoneInput) {
      return;
    }
    var maskOptions = {
      initialCountry: "auto",
      excludeCountries: ["by", "ru"],
      separateDialCode: true,
      customPlaceholder: function customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
        if (selectedCountryData.dialCode === '380') {
          selectedCountryPlaceholder = '50 123 4567';
        }
        return selectedCountryPlaceholder;
      },
      geoIpLookup: function geoIpLookup(callback) {
        fetch("https://ipapi.co/json").then(function (res) {
          return res.json();
        }).then(function (data) {
          return callback(data.country_code);
        })["catch"](function () {
          return callback("us");
        });
      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js"
    };
    var iti = intlTelInput(telephoneInput, maskOptions);
    var phoneCompleteInput = document.querySelector('input[name="phone-complete"]');
    if (!phoneCompleteInput) {
      return;
    }
    telephoneInput.addEventListener('input', function () {
      phoneCompleteInput.value = iti.getNumber();
    });
    telephoneInput.addEventListener('countrychange', function () {
      phoneCompleteInput.value = iti.getNumber();
    });
  };

  // const closeBurgerMenu = () => {

  //   const menuLinks = document.querySelectorAll('.header-mobile-menu a');

  //   if(!menuLinks.length){
  //     return;
  //   }

  //   menuLinks.forEach(link => {
  //     link.addEventListener('click', function(){
  //       this.closest('.mobile-menu__wrapper').classList.remove('visible');
  //       document.body.classList.remove('scroll-lock');
  //     })
  //   })
  // }

  var scrollSectionHandler = function scrollSectionHandler() {
    // check if gsap loaded/installed
    if ((typeof gsap === "undefined" ? "undefined" : _typeof(gsap)) !== 'object') {
      return;
    }
    var partnershipScrollTarget = document.querySelector("[data-scroll-target]");
    if (!partnershipScrollTarget) {
      return;
    }
    ScrollTrigger.matchMedia({
      "(min-width: 1161px)": function minWidth1161px() {
        var st = gsap.to(partnershipScrollTarget, {
          y: 670 - partnershipScrollTarget.clientHeight,
          scrollTrigger: {
            trigger: ".proposal-section .section-header",
            pin: "[data-scroll-section]",
            scrub: true,
            start: "top top",
            end: "+=670px",
            markers: false
          }
        });
      },
      "(min-width: 769px) and (max-width: 1160px)": function minWidth769pxAndMaxWidth1160px() {
        var st = gsap.to(partnershipScrollTarget, {
          y: 445 - partnershipScrollTarget.clientHeight,
          scrollTrigger: {
            trigger: "[data-scroll-section]",
            pin: "[data-scroll-section]",
            scrub: 1,
            start: "top top",
            end: "+=1500px",
            markers: false
          }
        });
      }
    });
  };
  var reviewsSlider = function reviewsSlider() {
    var BREAKPOINTS = {
      laptopXL: 1601,
      laptop: 1161,
      tablet: 841,
      mobile: 577
    };
    var slider = document.querySelector('[data-reviews-slider]');
    var statuses = document.querySelectorAll('[data-reviews-nav] .paging-info');
    var togglers = document.querySelectorAll('[data-reviews-slider] .review-card__show-more');
    var slidesQuantity = 0;
    if (!slider) {
      return;
    }
    if (statuses.length) {
      statuses.forEach(function (status) {
        $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1;
          var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
          var html = "<span class=\"current-slide color-blue\">".concat(i >= 10 ? i : '0' + i, "</span>\n                      <span>/</span>\n                      <span class=\"total-slides color-black\">").concat(totalSlides, "</span>");
          status.innerHTML = html;
        });
      });
    }
    $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      if (currentSlide === 0 || currentSlide === undefined) {
        $("[data-reviews-nav] .slide-prev").addClass('disabled');
      } else {
        $("[data-reviews-nav] .slide-prev").removeClass('disabled');
      }
      if (currentSlide === slick.$slides.length - 1) {
        $("[data-reviews-nav] .slide-next").addClass('disabled');
      } else {
        $("[data-reviews-nav] .slide-next").removeClass('disabled');
      }
    });
    if (togglers.length) {
      togglers.forEach(function (toggle) {
        toggle.addEventListener('click', function (event) {
          event.target.closest('.review-card__body').querySelector('.review-card__content').classList.toggle('expanded');
          $('[data-reviews-slider]').slick('setPosition');
        });
      });
    }
    slidesQuantity = slider.querySelectorAll('.review-card').length;
    var options = {
      dots: false,
      slidesToShow: 5,
      infinite: false,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: true,
      responsive: [{
        breakpoint: BREAKPOINTS.laptopXL,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: BREAKPOINTS.laptop,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: BREAKPOINTS.tablet,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: BREAKPOINTS.mobile,
        settings: {
          slidesToShow: 1,
          fade: true,
          cssEase: 'linear',
          speed: 100
        }
      }]
    };
    $(slider).slick(options);
    $("[data-reviews-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
    $("[data-reviews-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
    if (slidesQuantity <= 4) {
      var sliderNav = document.querySelector('.slider-nav.reviews-nav-dt');
      if (sliderNav) {
        sliderNav.style.visibility = 'hidden';
      }
    }
  };
  var tabsHandler = function tabsHandler() {
    var tabNav = document.querySelector('.tab-section .tab-nav');
    var tabBtns = document.querySelectorAll('.tab-section .tab-btn');
    var tabs = document.querySelectorAll('.tab-section .tab-content');
    if (!tabNav || !tabBtns.length || !tabs.length) {
      return;
    }
    tabNav.addEventListener('click', function (event) {
      var clickedBtn;
      var clickedBtnId;
      var targetTab;
      if (!event.target.closest('.tab-btn')) {
        return;
      }
      clickedBtn = event.target.closest('.tab-btn');
      clickedBtnId = clickedBtn.getAttribute('data-btn-id');
      tabBtns.forEach(function (btn) {
        btn.classList.remove('active');
      });
      clickedBtn.classList.add('active');
      tabs.forEach(function (tab) {
        tab.classList.remove('active');
        if (tab.getAttribute('data-tab-id') === clickedBtnId) {
          targetTab = tab;
        }
      });
      targetTab.classList.add('active');
    });
  };

  /* function marquee() {
    const marqueeElementsTop = document.querySelectorAll("[data-marquee-top]");
    const marqueeElementsBottom = document.querySelectorAll("[data-marquee-bottom]");
  
    if (marqueeElementsTop.length !== 0) {
      marqueeElementsTop.forEach((el) => animateMarquee(el, 30000));
    }
  
    if (marqueeElementsBottom.length !== 0) {
      marqueeElementsBottom.forEach((el) => animateMarquee(el, 20000));
    }
  }
  
  function animateMarquee(el, duration) {
    const innerEl = el.querySelector(".marquee__inner");
    const innerWidth = innerEl.offsetWidth;
    const cloneEl = innerEl.cloneNode(true);
    el.appendChild(cloneEl);
  
    let start = performance.now();
    let progress;
    let translateX;
  
    requestAnimationFrame(function step(now) {
      progress = (now - start) / duration;
  
      if (progress > 1) {
        progress %= 1;
        start = now;
      }
  
      translateX = innerWidth * progress;
  
      innerEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
      cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
      requestAnimationFrame(step);
    });
  }
  */

  var teamSlider = function teamSlider() {
    var BREAKPOINTS = {
      laptopXL: 1601,
      laptop: 1161,
      tablet: 841,
      mobile: 577
    };
    var slider = document.querySelector('[data-team-slider]');
    var statuses = document.querySelectorAll('[data-team-nav] .paging-info');
    var slidesQuantity = 0;
    if (!slider) {
      return;
    }
    if (statuses.length) {
      statuses.forEach(function (status) {
        $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1;
          var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
          var html = "<span class=\"current-slide color-blue\">".concat(i >= 10 ? i : '0' + i, "</span>\n                      <span>/</span>\n                      <span class=\"total-slides color-black\">").concat(totalSlides, "</span>");
          status.innerHTML = html;
        });
      });
    }
    $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      if (currentSlide === 0 || currentSlide === undefined) {
        $("[data-team-nav] .slide-prev").addClass('disabled');
      } else {
        $("[data-team-nav] .slide-prev").removeClass('disabled');
      }
      if (currentSlide === slick.$slides.length - 1) {
        $("[data-team-nav] .slide-next").addClass('disabled');
      } else {
        $("[data-team-nav] .slide-next").removeClass('disabled');
      }
    });
    slidesQuantity = slider.querySelectorAll('.team-gallery__slide').length;
    var options = {
      dots: false,
      slidesToShow: 5,
      infinite: true,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: true,
      responsive: [{
        breakpoint: BREAKPOINTS.laptopXL,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: BREAKPOINTS.laptop,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: BREAKPOINTS.tablet,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: BREAKPOINTS.mobile,
        settings: {
          slidesToShow: 1,
          fade: true,
          cssEase: 'linear',
          speed: 100
        }
      }]
    };
    $(slider).slick(options);
    $("[data-team-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
    $("[data-team-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
    if (slidesQuantity <= 4) {
      var sliderNav = document.querySelector('.slider-nav.reviews-nav-dt');
      if (sliderNav) {
        sliderNav.style.visibility = 'hidden';
      }
    }
  };
  var casesSlider = function casesSlider() {
    var slider = document.querySelector('[data-cases-slider]');
    var statuses = document.querySelectorAll('[data-cases-nav] .paging-info');
    if (!slider) {
      return;
    }
    if (statuses.length) {
      statuses.forEach(function (status) {
        $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1;
          var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
          var html = "<span class=\"current-slide color-blue\">".concat(i >= 10 ? i : '0' + i, "</span>\n                      <span>/</span>\n                      <span class=\"total-slides color-black\">").concat(totalSlides, "</span>");
          status.innerHTML = html;
        });
      });
    }
    var options = {
      dots: false,
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: true,
      fade: true,
      cssEase: 'linear',
      speed: 100
    };
    if (window.matchMedia('(max-width: 768px)').matches) {
      $(slider).slick(options);
    }
    window.addEventListener('resize', function () {
      if (window.innerWidth > 768) {
        if (slider.classList.contains('slick-initialized')) {
          $(slider).slick('unslick');
        }
        return;
      }
      if (!slider.classList.contains('slick-initialized')) {
        return $(slider).slick(options);
      }
    });
    $("[data-cases-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
    $("[data-cases-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
  };
  horizontalScrollHandler();
  mobileMenuToggle();
  mobileMenuHandler();
  refreshScrollTrigger();
  //tariffSlider();
  proposalSlider();
  reviewsSlider();
  closePopup();
  telephoneMask();
  scrollSectionHandler();
  tabsHandler();
  teamSlider();
  casesSlider();
  //marquee();

  var animationPaused = false;
  var animationProgress = 0;
  var animationStartTime = null;
  function marquee() {
    var marqueeElementsDt = document.querySelectorAll("[data-marquee]");
    if (marqueeElementsDt.length !== 0) {
      if (window.innerWidth > 768) {
        marqueeElementsDt.forEach(function (el) {
          return animateMarquee(el, 35000);
        });
      } else {
        marqueeElementsDt.forEach(function (el) {
          return animateMarquee(el, 35000);
        });
      }
    }
  }
  function animateMarquee(el, duration) {
    var innerEl = el.querySelector(".marquee-inner");
    var innerWidth = innerEl.offsetWidth;
    var cloneEl = innerEl.cloneNode(true);
    el.appendChild(cloneEl);
    function step(now) {
      if (!animationStartTime) {
        animationStartTime = now;
      }
      if (!animationPaused) {
        animationProgress = (now - animationStartTime) / duration;
        if (animationProgress > 1) {
          animationProgress %= 1;
          animationStartTime = now;
        }
        var translateX = innerWidth * animationProgress;
        innerEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
        cloneEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
      }
      requestAnimationFrame(step);
    }
    requestAnimationFrame(step);
  }
  marquee();
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
  });

  // form sent handler 

  document.addEventListener('wpcf7mailsent', function (event) {
    var formId = event.target.closest('[data-form-id]').dataset.formId;
    if (!formId) {
      return;
    }
    var popup = document.querySelector("[data-popup-id=\"".concat(formId, "\"]"));
    if (popup) {
      document.body.classList.add('scroll-lock');
      popup.classList.add('visible');
    }
  });
  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    };
  }
  $(".label__tab-toggle").on("click", function () {
    if (!$(this).next('div').hasClass('active')) {
      $(this).next('div').slideToggle(300).addClass('active');
    } else {
      $(this).next('div').slideUp(300, function () {
        $(this).removeClass('active');
      });
    }
    $(this).toggleClass('active');
  });
});